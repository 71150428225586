import React, { useEffect, useState } from 'react'
import {
    collection,
    getDocs,
    orderBy,
    query,
    doc,
    getDoc,
} from 'firebase/firestore'
import { db } from '../config/firebaseConfig'
import moment from 'moment'
import Loading from '../components/Loading'
import { useLocation } from 'wouter'
import {
    MessageSquare,
    X,
    ChevronLeft,
    ChevronRight,
    Menu,
    Download,
    FileDown,
} from 'lucide-react'

const formatTimestamp = (timestamp) => {
    try {
        const date = new Date(timestamp)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    } catch (error) {
        return 'Invalid date'
    }
}

const AdminPage = ({
    user,
    selectedDeal,
    setSelectedDeal,
    selectedAccount,
    setSelectedAccount,
    setDetailModalOpen,
    setAccountDetailsModalOpen,
}) => {
    const [deals, setDeals] = useState([])
    const [accounts, setAccounts] = useState([])
    const [messages, setMessages] = useState([])
    const [fetching, setFetching] = useState(true)
    const [location] = useLocation()
    const [activeTab, setActiveTab] = useState('submissions')
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedMessages, setSelectedMessages] = useState(null)
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
    const [hideCompletedDeals, setHideCompletedDeals] = useState(true)
    const [showMenu, setShowMenu] = useState(false)
    const [dealStatusFilter, setDealStatusFilter] = useState('active') // 'active', 'funded', 'declined', 'withdrawn'

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage] = useState(20)
    const [totalPages, setTotalPages] = useState(1)
    const [paginatedDeals, setPaginatedDeals] = useState([])
    const [paginatedAccounts, setPaginatedAccounts] = useState([])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const tab = params.get('tab') || 'submissions'
        setActiveTab(tab)
    }, [location])

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch all accounts first
                const accountsQuery = query(
                    collection(db, process.env.REACT_APP_USERS_DB_REF),
                    orderBy('createdAt', 'asc')
                )
                const accountsSnapshot = await getDocs(accountsQuery)
                const accountsData = accountsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setAccounts(accountsData)

                // Fetch all messages
                const allMessages = []
                for (const account of accountsData) {
                    if (account.messages) {
                        allMessages.push(...account.messages)
                    }
                }
                setMessages(allMessages)

                // Fetch all deals
                const dealsQuery = query(
                    collection(db, process.env.REACT_APP_DEALS_DB_REF),
                    orderBy('dateSubmitted', 'asc')
                )
                const dealsSnapshot = await getDocs(dealsQuery)

                // Process each deal and fetch associated user data
                const dealsPromises = dealsSnapshot.docs
                    .reverse()
                    .map(async (dealDoc) => {
                        const dealData = dealDoc.data()

                        // Fetch user document using userId
                        const userDoc = await getDoc(
                            doc(
                                db,
                                process.env.REACT_APP_USERS_DB_REF,
                                dealData.userId
                            )
                        )
                        const companyName = userDoc.exists()
                            ? userDoc.data().companyName
                            : ''

                        return {
                            id: dealDoc.id,
                            ...dealData,
                            companyName,
                        }
                    })

                const dealsData = await Promise.all(dealsPromises)
                setDeals(dealsData)
            } catch (error) {
                console.error('Error fetching data:', error)
            } finally {
                setFetching(false)
            }
        }

        fetchData()
    }, [user, selectedDeal, selectedAccount])

    // Apply pagination whenever filtered deals, current page, active tab, or filter status changes
    useEffect(() => {
        if (activeTab === 'submissions') {
            // First filter by business name
            let filtered = deals.filter((deal) =>
                deal.businessName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )

            // Then filter based on the selected status filter
            if (dealStatusFilter === 'active') {
                filtered = filtered.filter(
                    (deal) =>
                        !['Declined', 'Withdrawn', 'Funded'].includes(
                            deal.status
                        )
                )
            } else if (dealStatusFilter === 'funded') {
                filtered = filtered.filter((deal) => deal.status === 'Funded')
            } else if (dealStatusFilter === 'declined') {
                filtered = filtered.filter((deal) => deal.status === 'Declined')
            } else if (dealStatusFilter === 'withdrawn') {
                filtered = filtered.filter(
                    (deal) => deal.status === 'Withdrawn'
                )
            }

            setTotalPages(Math.ceil(filtered.length / itemsPerPage))

            const indexOfLastItem = currentPage * itemsPerPage
            const indexOfFirstItem = indexOfLastItem - itemsPerPage
            setPaginatedDeals(filtered.slice(indexOfFirstItem, indexOfLastItem))

            // Reset to page 1 if we're on a higher page than is now available
            if (
                currentPage > Math.ceil(filtered.length / itemsPerPage) &&
                filtered.length > 0
            ) {
                setCurrentPage(1)
            }
        } else if (activeTab === 'iso') {
            setTotalPages(Math.ceil(accounts.length / itemsPerPage))

            const indexOfLastItem = currentPage * itemsPerPage
            const indexOfFirstItem = indexOfLastItem - itemsPerPage
            setPaginatedAccounts(
                accounts.slice(indexOfFirstItem, indexOfLastItem)
            )

            // Reset to page 1 if we're on a higher page than is now available
            if (
                currentPage > Math.ceil(accounts.length / itemsPerPage) &&
                accounts.length > 0
            ) {
                setCurrentPage(1)
            }
        }
    }, [
        deals,
        accounts,
        currentPage,
        searchTerm,
        activeTab,
        itemsPerPage,
        dealStatusFilter,
    ])

    // Reset to page 1 when switching tabs, changing search term, or toggling filters
    useEffect(() => {
        setCurrentPage(1)
    }, [activeTab, searchTerm, dealStatusFilter])

    const highlightText = (text, highlight) => {
        if (!highlight.trim()) return text
        const regex = new RegExp(`(${highlight})`, 'gi')
        const parts = text.split(regex)
        return parts.map((part, i) =>
            regex.test(part) ? (
                <mark key={i} className='bg-yellow-200'>
                    {part}
                </mark>
            ) : (
                part
            )
        )
    }

    const handleViewMessages = (deal) => {
        const dealMessages = messages.filter((msg) => msg.dealId === deal.id)
        setSelectedMessages({ deal, messages: dealMessages })
        setIsMessageModalOpen(true)
    }

    const exportToCSV = (data, headers, fields, formatters, fileName) => {
        // Create the header row
        const headerRow = headers.join(',')

        // Create the data rows
        const rows = data
            .map((item) => {
                return fields
                    .map((field) => {
                        // Get the value, applying a formatter if one exists
                        let value = formatters[field]
                            ? formatters[field](item)
                            : item[field]

                        // Handle null or undefined values
                        if (value === null || value === undefined) {
                            return ''
                        }

                        // Convert to string and handle values that need to be quoted
                        value = String(value)
                        if (
                            value.includes(',') ||
                            value.includes('\n') ||
                            value.includes('"')
                        ) {
                            value = `"${value.replace(/"/g, '""')}"`
                        }
                        return value
                    })
                    .join(',')
            })
            .join('\n')

        const csvContent = `${headerRow}\n${rows}`

        // Create a Blob with the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

        // Create a download link and trigger the download
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const exportISOData = () => {
        const headers = [
            'Company Name',
            'DBA',
            'Primary Contact',
            'Email',
            'Telephone',
            'Office Number',
            'Address',
            'City',
            'State',
            'ZIP',
            'EIN',
            'Created At',
        ]

        const fields = [
            'companyName',
            'dba',
            'primaryContactPerson',
            'email',
            'telephone',
            'officeNumber',
            'address',
            'city',
            'state',
            'zip',
            'ein',
            'createdAt',
        ]

        const formatters = {
            createdAt: (item) => {
                if (item.createdAt && item.createdAt.seconds) {
                    const date = new Date(item.createdAt.seconds * 1000)
                    return date.toLocaleDateString()
                }
                return ''
            },
            address: (item) => {
                if (item.address) {
                    return item.address
                }
                return ''
            },
            dba: (item) => {
                if (!item.dba || item.dba.trim() === '') {
                    return 'N/A'
                }
                return item.dba
            },
        }

        exportToCSV(accounts, headers, fields, formatters, 'iso_data.csv')
    }

    const exportBusinessData = () => {
        const headers = [
            'Business Name',
            'DBA',
            'Status',
            'ISO',
            'ISO Referrer',
            'Contact Name',
            'Email',
            'Phone',
            'Address',
            'City',
            'State',
            'ZIP',
            'Industry',
            'Amount Approved',
            'Date Submitted',
        ]

        const fields = [
            'businessName',
            'dba',
            'status',
            'companyName',
            'referrer',
            'ownerName',
            'ownerEmail',
            'ownerTel',
            'address',
            'city',
            'state',
            'zip',
            'industry',
            'amountApproved',
            'dateSubmitted',
        ]

        const formatters = {
            dateSubmitted: (item) => {
                if (item.dateSubmitted && item.dateSubmitted.seconds) {
                    const date = new Date(item.dateSubmitted.seconds * 1000)
                    return date.toLocaleDateString()
                }
                return ''
            },
            amountApproved: (item) => {
                if (item.amountApproved) {
                    return `${item.amountApproved.toLocaleString()}`
                }
                return ''
            },
            dba: (item) => {
                if (!item.dba || item.dba.trim() === '') {
                    return 'N/A'
                }
                return item.dba
            },
        }

        exportToCSV(deals, headers, fields, formatters, 'business_data.csv')
    }

    const MessageModal = () => {
        if (!isMessageModalOpen || !selectedMessages) return null

        const { deal, messages: dealMessages } = selectedMessages

        return (
            <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]'>
                <div className='bg-white rounded-lg w-full max-w-2xl mx-4'>
                    <div className='flex justify-between items-center p-4 border-b border-gray-200'>
                        <h2 className='text-xl font-bold'>
                            Messages for {deal.businessName}
                        </h2>
                        <button
                            onClick={() => setIsMessageModalOpen(false)}
                            className='text-gray-500 hover:text-gray-700'
                        >
                            <X className='h-6 w-6' />
                        </button>
                    </div>

                    <div className='max-h-[400px] overflow-y-auto p-4'>
                        {dealMessages.length > 0 ? (
                            <div className='space-y-4'>
                                {dealMessages
                                    .sort((a, b) => b.timestamp - a.timestamp)
                                    .map((message, index) => (
                                        <div
                                            key={index}
                                            className={`p-4 rounded-lg border ${
                                                message.read
                                                    ? 'bg-gray-50'
                                                    : 'bg-white border-blue-200'
                                            }`}
                                        >
                                            <div className='flex justify-between items-start mb-2'>
                                                <span className='font-medium'>
                                                    {message.read
                                                        ? 'Read'
                                                        : 'Unread'}
                                                </span>
                                                <span className='text-sm text-gray-500'>
                                                    {formatTimestamp(
                                                        message.timestamp
                                                    )}
                                                </span>
                                            </div>
                                            <p className='text-gray-800'>
                                                {message.message}
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            <div className='text-center text-gray-500 py-8'>
                                No messages for this deal
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const PaginationControls = () => {
        return (
            <div className='flex justify-center items-center mt-6 mb-8'>
                <button
                    onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className={`p-2 mx-1 rounded-md ${
                        currentPage === 1
                            ? 'text-gray-400 cursor-not-allowed'
                            : 'text-gray-700 hover:bg-gray-200'
                    }`}
                >
                    <ChevronLeft className='h-5 w-5' />
                </button>

                <div className='mx-4 font-medium'>
                    Page {currentPage} of {totalPages}
                </div>

                <button
                    onClick={() =>
                        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    className={`p-2 mx-1 rounded-md ${
                        currentPage === totalPages
                            ? 'text-gray-400 cursor-not-allowed'
                            : 'text-gray-700 hover:bg-gray-200'
                    }`}
                >
                    <ChevronRight className='h-5 w-5' />
                </button>
            </div>
        )
    }

    const handleViewDeal = (deal) => {
        window.location = `/deal/modify/${deal.id}`
        setSelectedDeal(deal)
    }

    const handleViewAccount = (account) => {
        setSelectedAccount(account)
        setAccountDetailsModalOpen(true)
    }

    const handleTabChange = (tab) => {
        setActiveTab(tab)
        const currentPath = window.location.pathname
        const newUrl = `${currentPath}?tab=${tab}`
        window.history.pushState({}, '', newUrl)
    }

    const handleMenuItemClick = (status) => {
        setDealStatusFilter(status)
        setShowMenu(false)
    }

    return (
        <div className='bg-themeColorPrimary3 min-h-full flex flex-col w-full px-6 pt-6'>
            <div className='mt-[200px] md:mt-[130px] lg:mt-[130px]'>
                <div className='flex justify-center mb-6'>
                    <button
                        onClick={() => handleTabChange('submissions')}
                        className={`px-4 py-2 text-lg font-bold ${
                            activeTab === 'submissions'
                                ? 'border-b-4 border-[#078586]'
                                : ''
                        }`}
                    >
                        Submissions
                    </button>
                    <button
                        onClick={() => handleTabChange('iso')}
                        className={`px-4 py-2 text-lg font-bold ml-4 ${
                            activeTab === 'iso'
                                ? 'border-b-4 border-[#078586]'
                                : ''
                        }`}
                    >
                        ISO
                    </button>
                </div>

                {!fetching ? (
                    <>
                        {activeTab === 'submissions' && (
                            <div>
                                <div className='flex justify-between items-center mb-[30px]'>
                                    <h1 className='text-4xl font-bold my-4'>
                                        {dealStatusFilter === 'active'
                                            ? 'Active Deals'
                                            : dealStatusFilter === 'funded'
                                            ? 'Funded Deals'
                                            : dealStatusFilter === 'declined'
                                            ? 'Declined Deals'
                                            : 'Withdrawn Deals'}
                                    </h1>
                                    <div className='flex items-center gap-4'>
                                        <button
                                            onClick={() => exportBusinessData()}
                                            className='flex items-center px-4 py-2 bg-[#078586] text-white rounded-lg hover:bg-[#05686a] transition-colors'
                                        >
                                            <FileDown className='mr-2 h-5 w-5' />
                                            Export Business Data
                                        </button>

                                        <div className='relative'>
                                            <button
                                                onClick={() =>
                                                    setShowMenu(!showMenu)
                                                }
                                                className='flex items-center px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors'
                                            >
                                                <Menu className='mr-2 h-5 w-5' />
                                                View Options
                                            </button>

                                            {showMenu && (
                                                <div className='absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 border border-gray-200'>
                                                    <div className='py-1'>
                                                        <button
                                                            className={`block w-full text-left px-4 py-2 hover:bg-gray-100 ${
                                                                dealStatusFilter ===
                                                                'active'
                                                                    ? 'bg-gray-100'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                handleMenuItemClick(
                                                                    'active'
                                                                )
                                                            }
                                                        >
                                                            Active Deals
                                                        </button>
                                                        <button
                                                            className={`block w-full text-left px-4 py-2 hover:bg-gray-100 ${
                                                                dealStatusFilter ===
                                                                'funded'
                                                                    ? 'bg-gray-100'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                handleMenuItemClick(
                                                                    'funded'
                                                                )
                                                            }
                                                        >
                                                            Funded Deals
                                                        </button>
                                                        <button
                                                            className={`block w-full text-left px-4 py-2 hover:bg-gray-100 ${
                                                                dealStatusFilter ===
                                                                'declined'
                                                                    ? 'bg-gray-100'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                handleMenuItemClick(
                                                                    'declined'
                                                                )
                                                            }
                                                        >
                                                            Declined Deals
                                                        </button>
                                                        <button
                                                            className={`block w-full text-left px-4 py-2 hover:bg-gray-100 ${
                                                                dealStatusFilter ===
                                                                'withdrawn'
                                                                    ? 'bg-gray-100'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                handleMenuItemClick(
                                                                    'withdrawn'
                                                                )
                                                            }
                                                        >
                                                            Withdrawn Deals
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='mb-6'>
                                    <input
                                        type='text'
                                        placeholder='Search by business name...'
                                        value={searchTerm}
                                        onChange={(e) =>
                                            setSearchTerm(e.target.value)
                                        }
                                        className='w-full max-w-md px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#078586]'
                                    />
                                </div>

                                {paginatedDeals.length > 0 ? (
                                    <>
                                        <table className='w-full px-6'>
                                            <thead>
                                                <tr className='text-center text-xl'>
                                                    <th className='pb-4'>
                                                        Date
                                                    </th>
                                                    <th className='pb-4'>
                                                        Business Name
                                                    </th>
                                                    <th className='pb-4'>
                                                        ISO
                                                    </th>
                                                    <th className='pb-4'>
                                                        ISO Referrer
                                                    </th>
                                                    <th className='pb-4'>
                                                        Status
                                                    </th>
                                                    <th className='pb-4'>
                                                        Amount Approved
                                                    </th>
                                                    <th className='pb-4'>
                                                        Messages
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedDeals.map((deal) => (
                                                    <tr
                                                        key={deal.id}
                                                        className='mb-4 shadow-lg rounded-2xl border w-full cursor-pointer hover:shadow-xl hover:border-themeColorPrimary transition-all'
                                                    >
                                                        <td className='font-semibold text-base text-center mb-2 py-4'>
                                                            {moment(
                                                                deal.dateSubmitted.toDate()
                                                            ).format(
                                                                'MM - DD - YYYY'
                                                            )}
                                                        </td>
                                                        <td
                                                            className='font-semibold text-base text-center mb-2 py-4 underline'
                                                            onClick={() =>
                                                                handleViewDeal(
                                                                    deal
                                                                )
                                                            }
                                                        >
                                                            {highlightText(
                                                                deal.businessName,
                                                                searchTerm
                                                            )}
                                                        </td>
                                                        <td className='font-semibold text-base text-center mb-2 py-4'>
                                                            {deal.companyName}
                                                        </td>
                                                        <td className='text-base text-center mb-2 py-4 cursor-pointer font-bold'>
                                                            {deal.referrer}
                                                        </td>
                                                        <td className='font-semibold text-base text-center mb-2 py-4'>
                                                            {deal.status}
                                                        </td>
                                                        <td className='font-semibold text-base text-center mb-2 py-4'>
                                                            {deal?.amountApproved
                                                                ? `$${deal.amountApproved.toLocaleString()}`
                                                                : '-'}
                                                        </td>
                                                        <td className='text-center py-4'>
                                                            <div
                                                                className='relative inline-flex items-center cursor-pointer'
                                                                onClick={() =>
                                                                    handleViewMessages(
                                                                        deal
                                                                    )
                                                                }
                                                            >
                                                                <MessageSquare className='h-5 w-5 text-gray-500 hover:text-gray-700' />
                                                                {messages.filter(
                                                                    (msg) =>
                                                                        msg.dealId ===
                                                                            deal.id &&
                                                                        !msg.read
                                                                ).length >
                                                                    0 && (
                                                                    <span className='absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center'>
                                                                        {
                                                                            messages.filter(
                                                                                (
                                                                                    msg
                                                                                ) =>
                                                                                    msg.dealId ===
                                                                                        deal.id &&
                                                                                    !msg.read
                                                                            )
                                                                                .length
                                                                        }
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <PaginationControls />
                                    </>
                                ) : (
                                    <div>
                                        <table className='w-full px-6'>
                                            <thead>
                                                <tr className='text-center text-xl'>
                                                    <th className='pb-4'>
                                                        Date Submitted
                                                    </th>
                                                    <th className='pb-4'>
                                                        Business Name
                                                    </th>
                                                    <th className='pb-4'>
                                                        ISO
                                                    </th>
                                                    <th className='pb-4'>
                                                        ISO Referrer
                                                    </th>
                                                    <th className='pb-4'>
                                                        Status
                                                    </th>
                                                    <th className='pb-4'>
                                                        Amount Approved
                                                    </th>
                                                    <th className='pb-4'>
                                                        Messages
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <p className='w-full text-center mt-4 text-2xl font-bold text-red-600'>
                                            No deals found!
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}

                        {activeTab === 'iso' && (
                            <div>
                                <div className='flex justify-between items-center mb-[30px]'>
                                    <h1 className='text-4xl font-bold my-4'>
                                        All ISO
                                    </h1>
                                    <button
                                        onClick={() => exportISOData()}
                                        className='flex items-center px-4 py-2 bg-[#078586] text-white rounded-lg hover:bg-[#05686a] transition-colors'
                                    >
                                        <FileDown className='mr-2 h-5 w-5' />
                                        Export ISO Data
                                    </button>
                                </div>
                                {paginatedAccounts.length > 0 ? (
                                    <>
                                        <table className='w-full px-6'>
                                            <thead>
                                                <tr className='text-center text-xl'>
                                                    <th className='pb-4'>
                                                        ISO Name
                                                    </th>
                                                    <th className='pb-4'>
                                                        ISO Phone Number
                                                    </th>
                                                    <th className='pb-4'>
                                                        ISO Email
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedAccounts.map(
                                                    (account) => (
                                                        <tr
                                                            key={account.id}
                                                            className='mb-4 shadow-lg rounded-2xl border w-full'
                                                        >
                                                            <td
                                                                className='font-semibold text-base text-center mb-2 py-4 underline cursor-pointer'
                                                                onClick={() =>
                                                                    handleViewAccount(
                                                                        account
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    account.companyName
                                                                }
                                                            </td>
                                                            <td className='font-semibold text-base text-center mb-2 py-4'>
                                                                {
                                                                    account.telephone
                                                                }
                                                            </td>
                                                            <td className='font-semibold text-base text-center mb-2 py-4'>
                                                                {account.email}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                        <PaginationControls />
                                    </>
                                ) : (
                                    <p className='w-full text-center mt-4 text-2xl font-bold text-red-600'>
                                        No accounts found!
                                    </p>
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <div className='h-[300px] overflow-hidden'>
                        <Loading theme='primary' />
                    </div>
                )}
            </div>

            {/* Message Modal */}
            <MessageModal />
        </div>
    )
}

export default AdminPage
