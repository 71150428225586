import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { doc, getDoc, setDoc, arrayUnion } from 'firebase/firestore'
import { db } from '../config/firebaseConfig' // Firebase configuration
import { FiDownload, FiEye } from 'react-icons/fi'
import { useParams } from 'wouter'
import Loading from '../components/Loading'
import {
    findUserByEmail,
    getLastThreeMonths,
    getLastThreeYears,
    industryCategories,
    isAdmin,
    onlyNumbers,
    sendStatusChangeEmail,
} from '../utils/helpers'
import moment from 'moment'

const initialStyles = {
    businessName: {},
    dba: {},
    referrer: {},
    referrerEmail: {},
    useOfFunds: {},
    address: {},
    city: {},
    state: {},
    zip: {},
    tel: {},
    dateIncorporated: {},
    industry: {},
    businessDescription: {},
    ownerName: {},
    ownerTel: {},
    ownerEmail: {},
    ownerDOB: {},
    ownerSSN: {},
    pctOwnership: {},
    driversLicenseFront: {},
    driversLicenseBack: {},
    bankStatement1: {},
    bankStatement2: {},
    bankStatement3: {},
    loanApplication: {},
}

const DealDetailPage = ({ user, userInfo }) => {
    const [deal, setDeal] = useState(null)
    const [dealStatus, setDealStatus] = useState('')
    const [additionalFiles, setAdditionalFiles] = useState({
        bankStatementsAdditional: [],
        applicationsAdditional: [],
        businessTaxReturnsAdditional: [],
        personalTaxReturnsAdditional: [],
        taxExtensionsAdditional: [],
        miscellaneousAdditional: [],
    })
    const [loading, setLoading] = useState(false)
    const [submitStatusMessage, setSubmitStatusMessage] = useState('')
    const [isEditing, setIsEditing] = useState(false)
    const [editableFields, setEditableFields] = useState({
        referrer: '',
        referrerEmail: '',
        useOfFunds: '',
        businessName: '',
        dba: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        tel: '',
        dateIncorporated: '',
        industry: '',
        businessDescription: '',
        ownerName: '',
        ownerTel: '',
        ownerEmail: '',
        ownerDOB: '',
        ownerSSN: '',
        pctOwnership: '',
        driversLicenseFront: '',
        hasLargeLenderBalances: '',
        bankStatement1: '',
        bankStatement2: '',
        bankStatement3: '',
        loanApplication: '',
    })

    const MAX_FILE_SIZE = 50 * 1024 * 1024 // 50MB in bytes

    // eslint-disable-next-line
    const [formData, setFormData] = useState({
        referrer: '',
        referrerEmail: '',
        useOfFunds: '',
        businessName: '',
        dba: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        tel: '',
        dateIncorporated: '',
        industry: '',
        businessDescription: '',
        ownerName: '',
        ownerTel: '',
        ownerEmail: '',
        ownerDOB: '',
        ownerSSN: '',
        pctOwnership: '',
        driversLicenseFront: '',
        hasLargeLenderBalances: '',
        bankStatement1: '',
        bankStatement2: '',
        bankStatement3: '',
        loanApplication: '',
    })

    const [message, setMessage] = useState('')
    const [messageSubmitStatus, setMessageSubmitStatus] = useState('')

    const [dealUser, setDealUser] = useState({})

    const [selectedStatus, setSelectedStatus] = useState(dealStatus)
    const [statusChanged, setStatusChanged] = useState(false)

    const [urlParams] = useState(new URLSearchParams(window.location.search))
    // eslint-disable-next-line
    const messageTimestamp = urlParams.get('timestamp')
    const { dealId } = useParams()

    const [uploadingStates, setUploadingStates] = useState({})

    const [declineMessage, setDeclineMessage] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    // DealDetailPage.jsx update - Add amount input dialog
    const [amountInput, setAmountInput] = useState('')
    const [isSubmittingAmount, setIsSubmittingAmount] = useState(false)

    // Add this to the status change section in DealDetailPage
    const handleAmountSubmission = async () => {
        if (!amountInput || isNaN(amountInput)) {
            alert('Please enter a valid amount')
            return
        }

        setIsSubmittingAmount(true)
        try {
            // Update the deal with the approved amount
            await setDoc(
                doc(db, process.env.REACT_APP_DEALS_DB_REF, dealId),
                {
                    amountApproved: parseFloat(amountInput),
                    status: selectedStatus,
                },
                { merge: true }
            )

            const messageId = `msg_${Date.now()}_${Math.random()
                .toString(36)
                .substring(2, 15)}`
            const timestamp = new Date().toISOString()

            // Create message object
            const messageObj = {
                id: messageId,
                dealId: dealId,
                message: `APPROVED: $${parseFloat(
                    amountInput
                ).toLocaleString()} - ${
                    deal.businessName
                }\nStatus Description: The deal is approved for $${parseFloat(
                    amountInput
                ).toLocaleString()}.`,
                timestamp: timestamp,
                from: 'system',
                dealName: deal.businessName,
                status: selectedStatus,
                type: 'status_update',
            }

            // Update deal with status message
            await setDoc(
                doc(db, process.env.REACT_APP_DEALS_DB_REF, dealId),
                {
                    brokerMessages: arrayUnion(messageObj),
                    lastMessageTimestamp: new Date().toISOString(),
                },
                { merge: true }
            )

            // Also update ISO's messages collection
            if (deal.referrerEmail && dealUser?.uid) {
                await setDoc(
                    doc(db, process.env.REACT_APP_USERS_DB_REF, dealUser.uid),
                    {
                        messages: arrayUnion(messageObj),
                    },
                    { merge: true }
                )
            }

            await sendStatusChangeEmail(
                deal.referrer,
                deal.referrerEmail,
                dealUser.email,
                selectedStatus,
                deal.businessName,
                '',
                parseFloat(amountInput)
            )

            setIsSubmittingAmount(false)
            setDealStatus(selectedStatus)
            setStatusChanged(false)
            setSubmitStatusMessage('Status and amount updated successfully.')

            fetchDeal()
        } catch (error) {
            console.error('Error updating amount:', error)
            setSubmitStatusMessage('Error updating amount')
            setIsSubmittingAmount(false)
        }
    }

    const handleStatusChange = (event) => {
        const newStatus = event.target.value
        setSelectedStatus(newStatus)
        setStatusChanged(newStatus !== dealStatus)

        // Reset messages when changing status
        if (newStatus !== 'Declined') {
            setDeclineMessage('')
        }
        if (newStatus !== 'Question for Broker') {
            setMessage('')
        }
    }

    const handleSendMessage = async (messageType) => {
        const messageToSend =
            messageType === 'decline' ? declineMessage : message

        if (!messageToSend.trim()) {
            alert('Please enter a message')
            return
        }

        setIsSubmitting(true)
        setMessageSubmitStatus('')

        try {
            const messageId = `msg_${Date.now()}_${Math.random()
                .toString(36)
                .substring(2, 15)}`
            const timestamp = new Date().toISOString()

            const messageObj = {
                id: messageId,
                dealId: dealId,
                message:
                    messageType === 'decline'
                        ? `Declined: ${messageToSend}`
                        : `Question for Broker: BROKER ACTION: Response Required - ${messageToSend}`,
                timestamp: timestamp,
                from: user.email,
                dealName: deal.businessName,
                status: selectedStatus,
                read: false,
            }

            // Update deal with message
            await setDoc(
                doc(db, process.env.REACT_APP_DEALS_DB_REF, dealId),
                {
                    brokerMessages: arrayUnion(messageObj),
                    lastMessageTimestamp: timestamp,
                    status: selectedStatus,
                },
                { merge: true }
            )

            // Update user's messages
            await setDoc(
                doc(db, process.env.REACT_APP_USERS_DB_REF, dealUser.uid),
                {
                    messages: arrayUnion(messageObj),
                },
                { merge: true }
            )

            await sendStatusChangeEmail(
                deal.referrer,
                deal.referrerEmail,
                dealUser.email,
                selectedStatus,
                deal.businessName,
                messageToSend
            )

            if (messageType === 'decline') {
                setDeclineMessage('')
            } else {
                setMessage('')
            }
            setMessageSubmitStatus('Message sent successfully!')
            setTimeout(() => setMessageSubmitStatus(''), 3000)
        } catch (error) {
            console.error('Error sending message:', error)
            setMessageSubmitStatus('Error sending message')
        } finally {
            setIsSubmitting(false)
        }
    }

    useEffect(() => {
        setSelectedStatus(dealStatus)
    }, [dealStatus])

    const updateDealStatus = async () => {
        setLoading(true)

        try {
            // Always update the status in Firestore
            await setDoc(
                doc(db, process.env.REACT_APP_DEALS_DB_REF, dealId),
                {
                    status: selectedStatus,
                },
                { merge: true }
            )

            // Skip additional operations if status is "Question for Broker"
            if (selectedStatus !== 'Question for Broker') {
                // Get status message based on new status
                const statusMessage = getStatusMessage(
                    selectedStatus,
                    deal.businessName
                )

                const messageId = `msg_${Date.now()}_${Math.random()
                    .toString(36)
                    .substring(2, 15)}`
                const timestamp = new Date().toISOString()

                // Create message object for ISO notification
                const messageObj = {
                    id: messageId,
                    dealId: dealId,
                    message: statusMessage,
                    timestamp: timestamp,
                    from: 'system',
                    dealName: deal.businessName,
                    status: selectedStatus,
                    type: 'status_update',
                }

                // Update deal with status message
                await setDoc(
                    doc(db, process.env.REACT_APP_DEALS_DB_REF, dealId),
                    {
                        brokerMessages: arrayUnion(messageObj),
                        lastMessageTimestamp: new Date().toISOString(),
                    },
                    { merge: true }
                )

                // Also update ISO's messages collection
                if (deal.referrerEmail && dealUser?.uid) {
                    await setDoc(
                        doc(
                            db,
                            process.env.REACT_APP_USERS_DB_REF,
                            dealUser.uid
                        ),
                        {
                            messages: arrayUnion(messageObj),
                        },
                        { merge: true }
                    )
                }

                await sendStatusChangeEmail(
                    deal.referrer,
                    deal.referrerEmail,
                    dealUser.email,
                    selectedStatus,
                    deal.businessName
                )
            }

            setDealStatus(selectedStatus)
            setStatusChanged(false)
            setSubmitStatusMessage('Status updated successfully.')
            setLoading(false)
        } catch (error) {
            console.error('Error updating status:', error)
            setSubmitStatusMessage('Error updating status')
            setLoading(false)
        }
    }

    // Helper function to get status message
    const getStatusMessage = (status, merchantName) => {
        const statusMessages = {
            Pending: `New Submission - ${merchantName} - Status Description: New deal submission received, pending entry completion.`,

            'In Progress': `IN PROGRESS: Credit Review - ${merchantName}\nStatus Description: Deal is entered; Credit and statements are being reviewed for pricing.`,

            'Under Review': `UNDER REVIEW: Full Analysis - ${merchantName}\nStatus Description: Application is undergoing comprehensive underwriting review.`,

            Declined: `DECLINED: Decision Notice - ${merchantName}\nStatus Description: After careful review of the submitted application and supporting documentation, we are unable to approve this request at this time. A detailed explanation and any alternative options will be provided in the accompanying communication.`,

            'Closing Docs Out': `CLOSING DOCS: Ready for Review - ${merchantName}\nStatus Description: An offer has been accepted and the contracts are now available to review or to be sent for e-signature.`,

            'Closing Docs Signed': `DOCS SIGNED: Under Review - ${merchantName}\nStatus Description: Contracts have been signed and the application is being reviewed by one of our internal underwriters.`,

            Approved: `APPROVED: Ready to Fund - ${merchantName}\nStatus Description: The deal is fully approved without any funding stipulations.`,

            Funded: `FUNDED: Wire Sent - ${merchantName}\nStatus Description: The proceeds for the client have been sent via wire transfer. Those wire details will be provided in a separate email. If this funding required payoffs, those have been processed as well.`,
        }

        return (
            statusMessages[status] ||
            `Status Update: ${status} - ${merchantName}`
        )
    }

    const fetchDeal = async () => {
        setLoading(true)
        const dealDoc = await getDoc(
            doc(db, process.env.REACT_APP_DEALS_DB_REF, dealId)
        )
        if (dealDoc.exists()) {
            const dealData = dealDoc.data()
            setDeal(dealData)
            setEditableFields(dealData)
            setDealStatus(dealData.status)

            setAdditionalFiles({
                bankStatementsAdditional:
                    dealData.bankStatementsAdditional || [],
                applicationsAdditional: dealData.applicationsAdditional || [],
                businessTaxReturnsAdditional:
                    dealData.businessTaxReturnsAdditional || [], // Changed
                personalTaxReturnsAdditional:
                    dealData.personalTaxReturnsAdditional || [], // Added
                miscellaneousAdditional: dealData.miscellaneousAdditional || [],
                taxExtensionsAdditional: dealData.taxExtensionsAdditional || [], // Added
            })

            setFormData({
                ...dealData,
                bankStatementsAdditional:
                    dealData.bankStatementsAdditional || [],
                applicationsAdditional: dealData.applicationsAdditional || [],
                taxReturnsAdditional: dealData.taxReturnsAdditional || [],
                miscellaneousAdditional: dealData.miscellaneousAdditional || [],
            })

            setAmountInput(dealData.amountApproved)
            setLoading(false)
        } else {
            setLoading(false)
            console.error('Deal not found')
        }
    }

    useEffect(() => {
        fetchDeal()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (deal?.userEmail) {
            findUserByEmail(deal.userEmail).then((user) => {
                setDealUser(user)
            })
        }
        // eslint-disable-next-line
    }, [deal])

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!file || !(file instanceof Blob)) {
                // If the file is not provided or not a Blob, return 'N/A'
                resolve('N/A')
                return
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result.split(',')[1])
            reader.onerror = (error) => reject(error)
        })
    }

    // eslint-disable-next-line
    const [fieldStyles, setFieldStyles] = useState(initialStyles)

    const handleEditToggle = () => {
        setIsEditing(!isEditing)
    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target

        // Special handling for zip code
        if (name === 'zip') {
            // Remove any non-digit characters
            const numericValue = value.replace(/\D/g, '')
            // Limit to 5 digits
            const truncatedValue = numericValue.slice(0, 5)
            setEditableFields((prev) => ({ ...prev, [name]: truncatedValue }))
            return
        }

        setEditableFields((prev) => ({ ...prev, [name]: value }))
    }

    const saveEdits = async () => {
        try {
            setLoading(true)
            await setDoc(
                doc(db, process.env.REACT_APP_DEALS_DB_REF, dealId),
                editableFields,
                {
                    merge: true,
                }
            )
            setDeal(editableFields)
            setSubmitStatusMessage('Information updated successfully.')
            setIsEditing(false)
            setLoading(false)
        } catch (error) {
            console.error('Error saving edits:', error)
        }
    }

    const renderDocumentRow = (
        label,
        fileKey,
        categoryName,
        isMandatory = false
    ) => {
        const fileUrl = deal?.[fileKey]?.url ? deal?.[fileKey]?.url : null

        const formatTimestamp = (timestamp) => {
            if (!timestamp) return 'Not uploaded'
            return moment(timestamp).format('MMM DD, YYYY [at] hh:mm A')
        }

        const handleViewClick = (e) => {
            e.preventDefault()
            alert(
                'Your file has been downloaded and is ready to view. Please use a PDF viewer.'
            )
            window.open(fileUrl, '_blank')
        }

        const isAdditionalCategory = [
            'bankStatementsAdditional',
            'applicationsAdditional',
            'taxReturnsAdditional',
            'miscellaneousAdditional',
        ].includes(fileKey)

        return (
            <div className='flex flex-col mt-4 mb-4 w-full'>
                <div className='flex flex-col md:flex-row justify-between gap-4 p-4 border rounded-lg bg-white'>
                    <div className='flex-1'>
                        <span className='font-bold block'>
                            {label}
                            {isMandatory && (
                                <b className='text-red-600'>&nbsp;*</b>
                            )}
                        </span>
                        {fileUrl && (
                            <span className='text-sm text-gray-600 block mt-1'>
                                Uploaded:{' '}
                                {formatTimestamp(deal?.[fileKey]?.timestamp)}
                            </span>
                        )}
                    </div>
                    <div className='flex flex-col space-x-4 items-center w-full max-w-[500px]'>
                        {fileUrl && !isAdditionalCategory ? (
                            <div className='w-full flex flex-col md:flex-row gap-2'>
                                <a
                                    href={fileUrl}
                                    onClick={handleViewClick}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='w-[210px] cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition'
                                >
                                    <FiEye className='mr-1' /> View
                                </a>
                                <a
                                    href={fileUrl}
                                    download={deal?.[fileKey]?.name}
                                    className='w-full cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition'
                                >
                                    <FiDownload className='mr-1' /> Download
                                </a>
                            </div>
                        ) : (
                            <div className='w-full flex items-center'>
                                <DragAndDrop
                                    fileName={fileKey}
                                    categoryName={categoryName}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const renderAdditionalFiles = (files, category, categoryName) => {
        if (!files || files.length === 0) {
            return 'No additional files uploaded'
        }

        const handleViewClick = (e, fileUrl) => {
            e.preventDefault()
            alert(
                'Your file has been downloaded and is ready to view. Please use a PDF viewer.'
            )
            window.open(fileUrl, '_blank')
        }

        // Function to format timestamp
        const formatTimestamp = (timestamp) => {
            if (!timestamp) return 'N/A'
            return moment(timestamp).format('MMM DD, YYYY [at] hh:mm A')
        }

        return (
            <div className='flex flex-col space-y-4'>
                {files.map((file, index) => (
                    <div key={index} className='border rounded-lg p-4 bg-white'>
                        <div className='flex flex-col md:flex-row justify-between gap-4'>
                            <div className='flex-1'>
                                <span className='font-bold mb-2 block'>
                                    {categoryName} File {index + 1}
                                </span>
                                <span className='text-sm text-gray-600 block'>
                                    Uploaded: {formatTimestamp(file.timestamp)}
                                </span>
                            </div>
                            <div className='flex gap-2 md:w-[400px]'>
                                <a
                                    href={file.url}
                                    onClick={(e) => handleViewClick(e, file)}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='flex-1 cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition'
                                >
                                    <FiEye className='mr-1' /> View
                                </a>
                                <a
                                    href={file.url}
                                    download={file.url}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='flex-1 cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition'
                                >
                                    <FiDownload className='mr-1' /> Download
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const DragAndDrop = ({ fileName, categoryName }) => {
        const [isDragging, setIsDragging] = useState(false)
        const isUploading = uploadingStates[fileName]
        const isMultiple = fileName.includes('Additional')

        const getBorderStyle = () => {
            if (fieldStyles[fileName]?.outline === '2px solid red') {
                return 'border-red-500 bg-red-50'
            }
            if (fieldStyles[fileName]?.outline === '2px solid #078586') {
                return 'border-green-500 bg-green-50'
            }
            if (isDragging) {
                return 'border-blue-400 bg-blue-100' // Darker blue background when dragging
            }
            if (isUploading) {
                return 'border-yellow-400 bg-yellow-50'
            }
            return 'border-gray-300 hover:border-blue-300 hover:bg-blue-50' // Light blue highlight on hover
        }

        const handleDrop = async (e) => {
            e.preventDefault()
            setIsDragging(false)
            await handleFileUpload(e.dataTransfer.files, fileName)
        }

        const handleFileChange = async (e) => {
            await handleFileUpload(e.target.files, fileName)
        }

        return (
            <div className='flex items-center gap-2 mb-4 w-fit md:lg:w-full ml-auto'>
                <div>
                    <label
                        htmlFor={fileName}
                        className={`w-full md:lg:w-[150px] cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition-colors duration-200 ${
                            isUploading ? 'opacity-50 cursor-wait' : ''
                        }`}
                    >
                        {isUploading ? (
                            <div className='w-5 h-5 border-2 border-gray-500 border-t-transparent rounded-full animate-spin' />
                        ) : (
                            `+ Add ${
                                fileName.includes('Additional')
                                    ? `${categoryName} Files`
                                    : ''
                            }`
                        )}
                    </label>
                    <input
                        id={fileName}
                        type='file'
                        onChange={handleFileChange}
                        className='hidden'
                        multiple={isMultiple}
                        disabled={isUploading}
                    />
                </div>
                <div
                    className={`hidden md:flex lg:flex items-center justify-center border border-dashed ${getBorderStyle()} p-2 rounded-md w-full transition-colors duration-200 cursor-pointer`}
                    onDragOver={(e) => {
                        e.preventDefault()
                        setIsDragging(true)
                    }}
                    onDragLeave={() => setIsDragging(false)}
                    onDrop={handleDrop}
                >
                    <p className='text-sm text-gray-500'>
                        {isUploading
                            ? 'Uploading...'
                            : 'Drag & drop files here'}
                    </p>
                </div>
            </div>
        )
    }

    const handleFileUpload = async (files, fileName) => {
        try {
            setUploadingStates((prev) => ({ ...prev, [fileName]: true }))

            const validFiles = Array.from(files).filter((file) => {
                if (file.size > MAX_FILE_SIZE) {
                    alert(`File "${file.name}" exceeds 50MB size limit`)
                    return false
                }
                return true
            })

            if (validFiles.length === 0) {
                setUploadingStates((prev) => ({ ...prev, [fileName]: false }))
                return
            }

            const fileData = await Promise.all(
                validFiles.map(async (file) => ({
                    name: fileName,
                    content: await fileToBase64(file),
                    type: file.type,
                    originalName: file.name,
                }))
            )

            // Upload to Firebase Storage through backend
            const response = await axios.post(
                'https://us-central1-quidity-app.cloudfunctions.net/uploadFile',
                // 'http://127.0.0.1:5001/quidity-app/us-central1/uploadFile',
                {
                    file: fileData[0], // For single file upload
                    files: fileName.includes('Additional') ? fileData : null, // For multiple files
                    userId: dealUser.userId,
                    docId: dealId,
                    fileCategory: fileName,
                    isUserRegistration: false,
                }
            )

            if (!fileName.includes('Additional')) {
                const fileInfo = {
                    timestamp: new Date().toISOString(),
                    url: response.data.fileUrl,
                    originalName: validFiles[0].name,
                }

                // Update local state
                setFormData((prev) => ({
                    ...prev,
                    [fileName]: fileInfo,
                }))
                setDeal((prev) => ({
                    ...prev,
                    [fileName]: fileInfo,
                }))

                // Update Firebase document
                await setDoc(
                    doc(db, process.env.REACT_APP_DEALS_DB_REF, dealId),
                    {
                        [fileName]: fileInfo,
                        lastUpdated: new Date().toISOString(),
                    },
                    { merge: true }
                )
            } else {
                const newFiles = validFiles.map((file, index) => ({
                    url: response.data.fileUrl,
                    timestamp: new Date().toISOString(),
                    originalName: file.name,
                }))

                // Update local state for additional files
                setFormData((prev) => ({
                    ...prev,
                    [fileName]: [...(prev[fileName] || []), ...newFiles],
                }))
                setAdditionalFiles((prev) => ({
                    ...prev,
                    [fileName]: [...(prev[fileName] || []), ...newFiles],
                }))

                // Update Firebase document for additional files
                await setDoc(
                    doc(db, process.env.REACT_APP_DEALS_DB_REF, dealId),
                    {
                        [fileName]: arrayUnion(...newFiles),
                        lastUpdated: new Date().toISOString(),
                    },
                    { merge: true }
                )
            }

            // Send notifications about new document upload
            await axios.post('https://eo5udo9oq3rjgqi.m.pipedream.net', {
                textContent: `New Docs have been uploaded. ISO NAME: ${dealUser.companyName} for ${deal.businessName}`,
            })

            const isoName = dealUser.companyName
            const isoContact = dealUser.telephone

            await axios.post('https://eo3g4kfpldaav1h.m.pipedream.net', {
                isoEmail: dealUser.email,
                businessName: deal.businessName,
                isoName,
                isoContact,
                date: `${moment().format('MM/DD/YYYY')}`,
            })
        } catch (error) {
            console.error('Error uploading file:', error)
            alert('File upload failed. Please try again.')
        } finally {
            setUploadingStates((prev) => ({ ...prev, [fileName]: false }))
        }
    }

    // Usage of the helper functions
    const lastThreeMonths = getLastThreeMonths()
    const lastThreeYears = getLastThreeYears()

    if (loading || deal === null) {
        return (
            <div className='h-screen'>
                <Loading />
            </div>
        )
    }

    return (
        <div className='container mx-auto py-8 w-[95%] md:w-[70%] lg:w-[70%] mt-[200px]'>
            <h2 className='text-3xl font-bold mb-4'>
                {isEditing ? (
                    <input
                        type='text'
                        name='businessName'
                        value={
                            editableFields?.businessName || deal.businessName
                        }
                        onChange={handleFieldChange}
                        className='border p-2 rounded-md'
                    />
                ) : (
                    'Business Name: ' + deal?.businessName
                )}
            </h2>
            {/* Status Change */}
            {isAdmin(user) && (
                <section className='mb-8 flex gap-2 items-center'>
                    <h3 className='text-xl font-bold'>Deal Status:</h3>
                    <select
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        className='p-2 border rounded-md cursor-pointer'
                    >
                        <option value='Pending'>Pending</option>
                        <option value='In Progress'>In Progress</option>
                        <option value='Under Review'>Under Review</option>
                        <option value='Question for Broker'>
                            Question for Broker
                        </option>
                        <option value='Declined'>Declined</option>
                        <option value='Closing Docs Out'>
                            Closing Docs Out
                        </option>
                        <option value='Closing Docs Signed'>
                            Closing Docs Signed
                        </option>
                        <option value='Approved'>Approved</option>
                        <option value='Funded'>Funded</option>
                        <option value='Initial Docs Sent'>
                            Initial Docs Sent
                        </option>
                        <option value='Awaiting Transcripts'>
                            Awaiting Transcripts
                        </option>
                        <option value='Bank Review'>Bank Review</option>
                        <option value='Submitted for pre-approval'>
                            Submitted for pre-approval
                        </option>
                        <option value='Pre-approval email sent'>
                            Pre-approval email sent
                        </option>
                        <option value='In packaging department'>
                            In packaging department
                        </option>
                        <option value='Pre-underwriting'>
                            Pre-underwriting
                        </option>
                        <option value='In Closing committee'>
                            In Closing committee
                        </option>
                        <option value='Withdrawn'>Withdrawn</option>
                    </select>
                    <button
                        onClick={updateDealStatus}
                        disabled={
                            !statusChanged ||
                            selectedStatus === 'Question for Broker' ||
                            selectedStatus === 'Declined' ||
                            selectedStatus === 'Approved'
                        }
                        className='px-4 py-2 bg-themeColorPrimary text-white rounded-md hover:bg-opacity-90 disabled:opacity-50'
                    >
                        Update Status
                    </button>
                </section>
            )}

            {isAdmin(user) && selectedStatus === 'Approved' && (
                <div className='flex flex-col gap-2 mb-8'>
                    <div className='flex items-center gap-4'>
                        <input
                            type='text'
                            value={amountInput}
                            onChange={(e) =>
                                setAmountInput(onlyNumbers(e.target.value))
                            }
                            placeholder='Enter approved amount'
                            className='themed-input-edit flex-1'
                            disabled={isSubmittingAmount}
                        />
                        <button
                            onClick={handleAmountSubmission}
                            disabled={
                                isSubmittingAmount ||
                                !amountInput ||
                                amountInput === deal.amountApproved
                            }
                            className='px-4 py-2 bg-themeColorPrimary text-white rounded-md hover:bg-opacity-90 disabled:opacity-50'
                        >
                            {isSubmittingAmount
                                ? 'Updating...'
                                : 'Update Amount'}
                        </button>
                    </div>
                </div>
            )}

            {/* Message box for Question for Broker */}
            {selectedStatus === 'Question for Broker' && isAdmin(user) && (
                <div className='flex flex-col gap-2 mb-8'>
                    <div className='flex items-center gap-4'>
                        <input
                            type='text'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder='Please type your message to broker'
                            className='themed-input-edit flex-1'
                            disabled={isSubmitting}
                        />
                        <button
                            onClick={() => handleSendMessage('broker')}
                            disabled={isSubmitting}
                            className='px-4 py-2 bg-themeColorPrimary text-white rounded-md hover:bg-opacity-90 disabled:opacity-50'
                        >
                            {isSubmitting ? 'Sending...' : 'Send'}
                        </button>
                    </div>
                </div>
            )}

            {/* New Message box for Declined status */}
            {selectedStatus === 'Declined' && isAdmin(user) && (
                <div className='flex flex-col gap-2 mb-8'>
                    <div className='flex items-center gap-4'>
                        <input
                            type='text'
                            value={declineMessage}
                            onChange={(e) => setDeclineMessage(e.target.value)}
                            placeholder='Please provide reason for decline'
                            className='themed-input-edit flex-1'
                            disabled={isSubmitting}
                        />
                        <button
                            onClick={() => handleSendMessage('decline')}
                            disabled={isSubmitting}
                            className='px-4 py-2 bg-red-600 text-white rounded-md hover:bg-opacity-90 disabled:opacity-50'
                        >
                            {isSubmitting ? 'Sending...' : 'Send'}
                        </button>
                    </div>
                </div>
            )}

            {messageSubmitStatus && (
                <div
                    className={`text-sm p-4 rounded-md ${
                        messageSubmitStatus.includes('Error')
                            ? 'bg-red-100 border border-red-600 text-red-600 font-semibold'
                            : 'bg-green-100 border border-green-800 text-green-800 font-semibold'
                    }`}
                >
                    {messageSubmitStatus}
                </div>
            )}

            <div className='mb-4 flex flex-col md:flex-row w-full justify-between gap-8 p-6 border border-gray-200 rounded-lg bg-gray-50'>
                {/* Business Information Column */}
                <section className='w-full md:w-1/2'>
                    <h2 className='text-lg font-bold mb-4 border-b border-gray-300 pb-2'>
                        Business Information:
                    </h2>
                    <div className='space-y-4'>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Referrer Name:
                            </label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='referrer'
                                    value={
                                        editableFields.referrer || deal.referrer
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.referrer}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Referrer Email:
                            </label>
                            {isEditing ? (
                                <input
                                    type='email'
                                    name='referrerEmail'
                                    value={
                                        editableFields.referrerEmail ||
                                        deal.referrerEmail
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.referrerEmail}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Industry:
                            </label>
                            {isEditing ? (
                                <select
                                    name='industry'
                                    value={
                                        editableFields.industry || deal.industry
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white cursor-pointer'
                                >
                                    <option value=''>Select Industry</option>
                                    {Object.keys(industryCategories).map(
                                        (ind) => (
                                            <option key={ind} value={ind}>
                                                {ind}
                                            </option>
                                        )
                                    )}
                                </select>
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.industry}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Description:
                            </label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='businessDescription'
                                    value={
                                        editableFields.businessDescription ||
                                        deal.businessDescription
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.businessDescription}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Address:
                            </label>
                            {isEditing ? (
                                <div className='space-y-2'>
                                    <div>
                                        <label className='text-sm text-gray-600'>
                                            Street Address:
                                        </label>
                                        <input
                                            type='text'
                                            name='address'
                                            value={
                                                editableFields.address ||
                                                deal.address
                                            }
                                            onChange={handleFieldChange}
                                            className='w-full p-2 border rounded bg-white'
                                        />
                                    </div>
                                    <div>
                                        <label className='text-sm text-gray-600'>
                                            City:
                                        </label>
                                        <input
                                            type='text'
                                            name='city'
                                            value={
                                                editableFields.city || deal.city
                                            }
                                            onChange={handleFieldChange}
                                            className='w-full p-2 border rounded bg-white'
                                        />
                                    </div>
                                    <div className='flex gap-2'>
                                        <div className='w-1/2'>
                                            <label className='text-sm text-gray-600'>
                                                State:
                                            </label>
                                            <select
                                                name='state'
                                                value={
                                                    editableFields.state ||
                                                    deal.state
                                                }
                                                onChange={handleFieldChange}
                                                className='w-full p-2 border rounded bg-white cursor-pointer'
                                            >
                                                <option value=''>
                                                    Select State
                                                </option>
                                                {[
                                                    'AL',
                                                    'AK',
                                                    'AZ',
                                                    'AR',
                                                    'CA',
                                                    'CO',
                                                    'CT',
                                                    'DE',
                                                    'FL',
                                                    'GA',
                                                    'HI',
                                                    'ID',
                                                    'IL',
                                                    'IN',
                                                    'IA',
                                                    'KS',
                                                    'KY',
                                                    'LA',
                                                    'ME',
                                                    'MD',
                                                    'MA',
                                                    'MI',
                                                    'MN',
                                                    'MS',
                                                    'MO',
                                                    'MT',
                                                    'NE',
                                                    'NV',
                                                    'NH',
                                                    'NJ',
                                                    'NM',
                                                    'NY',
                                                    'NC',
                                                    'ND',
                                                    'OH',
                                                    'OK',
                                                    'OR',
                                                    'PA',
                                                    'RI',
                                                    'SC',
                                                    'SD',
                                                    'TN',
                                                    'TX',
                                                    'UT',
                                                    'VT',
                                                    'VA',
                                                    'WA',
                                                    'WV',
                                                    'WI',
                                                    'WY',
                                                ].map((state) => (
                                                    <option
                                                        key={state}
                                                        value={state}
                                                    >
                                                        {state}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='w-1/2'>
                                            <label className='text-sm text-gray-600'>
                                                ZIP Code:
                                            </label>
                                            <input
                                                type='text'
                                                name='zip'
                                                value={
                                                    editableFields.zip ||
                                                    deal.zip
                                                }
                                                onChange={handleFieldChange}
                                                className='w-full p-2 border rounded bg-white'
                                                pattern='[0-9]{5}'
                                                maxLength='5'
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {`${deal.address}, ${deal.city}, ${deal.state} ${deal.zip}`}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Incorporated on:
                            </label>
                            {isEditing ? (
                                <input
                                    type='date'
                                    name='dateIncorporated'
                                    value={
                                        editableFields.dateIncorporated ||
                                        deal.dateIncorporated
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    max='9999-12-31'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.dateIncorporated}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                {'MCA>$50k:'}
                            </label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='hasLargeLenderBalances'
                                    value={
                                        editableFields.hasLargeLenderBalances ||
                                        deal.hasLargeLenderBalances
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.hasLargeLenderBalances}
                                </div>
                            )}
                        </div>
                        {/* <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Use of funds:
                            </label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='useOfFunds'
                                    value={
                                        editableFields.useOfFunds ||
                                        deal.useOfFunds ||
                                        'N/A'
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.useOfFunds || 'N/A'}
                                </div>
                            )}
                        </div> */}
                    </div>
                </section>

                {/* Owner Information Column */}
                <section className='w-full md:w-1/2'>
                    <h2 className='text-lg font-bold mb-4 border-b border-gray-300 pb-2'>
                        Owner Information
                    </h2>
                    <div className='space-y-4'>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>Owner:</label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='ownerName'
                                    value={
                                        editableFields.ownerName ||
                                        deal.ownerName
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.ownerName}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>Email:</label>
                            {isEditing ? (
                                <input
                                    type='email'
                                    name='ownerEmail'
                                    value={
                                        editableFields.ownerEmail ||
                                        deal.ownerEmail
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    max='9999-12-31'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.ownerEmail}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Date of Birth:
                            </label>
                            {isEditing ? (
                                <input
                                    type='date'
                                    name='ownerDOB'
                                    value={
                                        editableFields.ownerDOB || deal.ownerDOB
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    max='9999-12-31'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.ownerDOB}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>SSN:</label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='ownerSSN'
                                    value={
                                        editableFields.ownerSSN || deal.ownerSSN
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    maxLength='9'
                                    pattern='[0-9]{9}'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.ownerSSN}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Telephone:
                            </label>
                            {isEditing ? (
                                <input
                                    type='tel'
                                    name='ownerTel'
                                    value={
                                        editableFields.ownerTel || deal.ownerTel
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    maxLength='10'
                                    pattern='[0-9]{10}'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.ownerTel}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Ownership:
                            </label>
                            {isEditing ? (
                                <select
                                    name='pctOwnership'
                                    value={
                                        editableFields.pctOwnership ||
                                        deal.pctOwnership
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    required
                                >
                                    <option value=''>Select Percentage</option>
                                    <option value='10'>10%</option>
                                    <option value='20'>20%</option>
                                    <option value='30'>30%</option>
                                    <option value='40'>40%</option>
                                    <option value='50'>50%</option>
                                    <option value='60'>60%</option>
                                    <option value='70'>70%</option>
                                    <option value='80'>80%</option>
                                    <option value='90'>90%</option>
                                    <option value='100'>100%</option>
                                </select>
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.pctOwnership}%
                                </div>
                            )}
                        </div>
                    </div>
                    {isAdmin(user) && (
                        <div className='flex justify-end mt-6'>
                            <button
                                className={`px-6 py-2.5 rounded-md font-medium transition-colors duration-200 ${'bg-themeColorPrimary text-white hover:bg-opacity-90'}`}
                                onClick={
                                    isEditing ? saveEdits : handleEditToggle
                                }
                            >
                                {isEditing ? (
                                    loading ? (
                                        <span className='flex items-center gap-2'>
                                            <svg
                                                className='animate-spin h-4 w-4'
                                                viewBox='0 0 24 24'
                                            >
                                                <circle
                                                    className='opacity-25'
                                                    cx='12'
                                                    cy='12'
                                                    r='10'
                                                    stroke='currentColor'
                                                    strokeWidth='4'
                                                    fill='none'
                                                />
                                                <path
                                                    className='opacity-75'
                                                    fill='currentColor'
                                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                />
                                            </svg>
                                            Saving...
                                        </span>
                                    ) : (
                                        'Save Changes'
                                    )
                                ) : (
                                    'Modify'
                                )}
                            </button>
                        </div>
                    )}
                </section>
            </div>

            {/* Documents Section */}
            <section className='mb-8'>
                {/* <h3 className="text-xl font-bold mb-4 underline">Documents</h3> */}

                {/* Bank Statements */}
                <h4 className='text-lg font-bold mb-2 underline'>
                    Bank Statements
                </h4>
                {lastThreeMonths.map((monthData, index) =>
                    renderDocumentRow(
                        `Bank Statement ${monthData.monthName} ${monthData.year}`,
                        `bankStatement${monthData.monthName}${monthData.year}`,
                        true
                    )
                )}
                {/* Additional File Upload Sections */}
                {renderAdditionalFiles(
                    additionalFiles?.bankStatementsAdditional || [],
                    'bankStatementsAdditional',
                    'Bank'
                )}
                {renderDocumentRow('', 'bankStatementsAdditional', 'Bank')}

                {/* Applications */}
                <h4 className='text-lg font-bold mt-8 mb-2 underline'>
                    Applications
                </h4>
                {renderDocumentRow('Loan Application', 'loanApplication', true)}
                {renderAdditionalFiles(
                    additionalFiles?.applicationsAdditional || [],
                    'applicationsAdditional',
                    'App'
                )}
                {renderDocumentRow('', 'applicationsAdditional', 'App')}

                {/* Tax Documents */}
                <h3 className='text-lg underline font-bold mb-4'>
                    Tax Returns:
                </h3>
                {/* Business Tax Returns */}
                <h4 className='text-md font-bold mb-2 underline'>
                    Business Tax Returns
                </h4>
                {lastThreeYears.map((year) =>
                    renderDocumentRow(
                        `Business Tax Return ${year}`,
                        `taxReturn${year}`,
                        true
                    )
                )}
                {renderAdditionalFiles(
                    additionalFiles?.businessTaxReturnsAdditional || [],
                    'businessTaxReturnsAdditional',
                    'Tax'
                )}
                {renderDocumentRow('', 'businessTaxReturnsAdditional', 'Tax')}

                {/* Personal Tax Returns */}
                <h4 className='text-md font-bold mt-6 mb-2 underline'>
                    Personal Tax Returns
                </h4>
                {lastThreeYears.map((year) =>
                    renderDocumentRow(
                        `Personal Tax Return ${year}`,
                        `personalTaxReturn${year}`,
                        true
                    )
                )}
                {renderAdditionalFiles(
                    additionalFiles?.personalTaxReturnsAdditional || [],
                    'personalTaxReturnsAdditional',
                    'Tax'
                )}
                {renderDocumentRow('', 'personalTaxReturnsAdditional', 'Tax')}

                {/* Tax Extensions */}
                <h4 className='text-md font-bold mt-6 mb-2 underline'>
                    Tax Extensions
                </h4>
                {renderDocumentRow(
                    `Business Tax Extension ${new Date().getFullYear() - 1}`,
                    `businessTaxExtension${new Date().getFullYear() - 1}`
                )}
                {renderDocumentRow(
                    `Personal Tax Extension ${new Date().getFullYear() - 1}`,
                    `personalTaxExtension${new Date().getFullYear() - 1}`
                )}

                {renderAdditionalFiles(
                    additionalFiles?.taxExtensionsAdditional || [],
                    'taxExtensionsAdditional',
                    'Tax'
                )}
                {renderDocumentRow('', 'taxExtensionsAdditional', 'Tax')}

                {/* Miscellaneous Documents */}
                <h4 className='text-lg font-bold mt-8 mb-2 underline'>
                    Miscellaneous Documents
                </h4>
                {renderDocumentRow(
                    "Driver's License Front",
                    'driversLicenseFront',
                    true
                )}
                {renderDocumentRow('Voided Check', 'voidedCheck', false)}
                {renderDocumentRow('Credit Report', 'creditReport', false)}
                {renderDocumentRow(
                    `P & L Statement ${new Date().getFullYear() - 1}`,
                    `plStatement${new Date().getFullYear() - 1}`
                )}
                {renderDocumentRow(
                    `Accounts Receivable ${new Date().getFullYear() - 1}`,
                    `accountsReceivable${new Date().getFullYear() - 1}`
                )}
                {renderDocumentRow(
                    `Accounts Payables ${new Date().getFullYear() - 1}`,
                    `accountsPayables${new Date().getFullYear() - 1}`
                )}

                {renderAdditionalFiles(
                    additionalFiles?.miscellaneousAdditional || [],
                    'miscellaneousAdditional',
                    'Misc'
                )}
                {renderDocumentRow('', 'miscellaneousAdditional', 'Misc')}
            </section>
            {submitStatusMessage && (
                <div
                    className={`mt-4 p-4 rounded-md ${
                        submitStatusMessage.toLowerCase().includes('error')
                            ? 'bg-red-100 border border-red-400 text-red-700'
                            : 'bg-green-100 border border-green-400 text-green-700'
                    } font-medium text-sm`}
                >
                    {submitStatusMessage}
                </div>
            )}
        </div>
    )
}

export default DealDetailPage
