import React, { useEffect, useState } from 'react'
import Modal from '../components/Modal'
import { FiDownload } from 'react-icons/fi'
import { fetchUserInfo } from '../utils/helpers'

const AccountDetailModal = ({ user, selectedAccount, onClose }) => {
    // eslint-disable-next-line
    const [accountUser, setAccountUser] = useState(null)

    useEffect(() => {
        fetchUserInfo(user.uid).then((userObject) => {
            setAccountUser(userObject)
        })

        console.log(JSON.stringify(selectedAccount))
        // eslint-disable-next-line
    }, [selectedAccount])

    // Function to convert account data to CSV
    const convertToCSV = (account) => {
        // Define CSV headers
        const headers = [
            'Company Name',
            'DBA',
            'Primary Contact',
            'Email',
            'Telephone',
            'Office Number',
            'Address',
            'City',
            'State',
            'ZIP',
            'EIN',
            'Created At',
        ]

        // Format creation date
        const createdAtDate = new Date(
            account.createdAt.seconds * 1000
        ).toLocaleDateString()

        // Prepare data row
        const data = [
            account.companyName,
            account.dba || 'N/A',
            account.primaryContactPerson,
            account.contactEmail,
            account.telephone,
            account.officeNumber,
            account.address,
            account.city,
            account.state,
            account.zip,
            account.ein,
            createdAtDate,
        ]

        // Escape any commas in the data
        const escapedData = data.map((item) => {
            // If item contains commas or quotes, wrap in quotes and escape any quotes
            if (
                typeof item === 'string' &&
                (item.includes(',') || item.includes('"'))
            ) {
                return `"${item.replace(/"/g, '""')}"`
            }
            return item
        })

        // Join headers and data to create CSV content
        const csvContent = [headers.join(','), escapedData.join(',')].join('\n')

        return csvContent
    }

    // Function to download CSV
    const downloadCSV = () => {
        if (!selectedAccount) return

        const csvContent = convertToCSV(selectedAccount)
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute(
            'download',
            `${selectedAccount.companyName.replace(/\s+/g, '_')}_info.csv`
        )
        link.style.visibility = 'hidden'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        selectedAccount !== null && (
            <>
                <Modal onClose={onClose}>
                    <div className='mt-3 text-left'>
                        <div className='flex justify-between items-center mb-4'>
                            <h2 className='text-xl font-semibold'>
                                {selectedAccount.companyName}
                            </h2>
                            <button
                                onClick={downloadCSV}
                                className='flex items-center px-3 py-2 bg-themeColorPrimary text-white rounded-md hover:bg-themeColorPrimary transition-colors'
                            >
                                <FiDownload className='mr-2' />
                                Export CSV
                            </button>
                        </div>

                        <section className='mb-4'>
                            <h3 className='text-lg underline font-bold mb-2'>
                                Company Information:
                            </h3>
                            <p>
                                <b>Company Name:</b>{' '}
                                {selectedAccount.companyName}
                            </p>
                            <p>
                                <b>DBA:</b> {selectedAccount.dba || 'N/A'}
                            </p>
                            <p>
                                <b>Primary Contact:</b>{' '}
                                {selectedAccount.primaryContactPerson}
                            </p>
                            <p>
                                <b>Email:</b> {selectedAccount.contactEmail}
                            </p>
                            <p>
                                <b>Telephone:</b> {selectedAccount.telephone}
                            </p>
                            <p>
                                <b>Office Number:</b>{' '}
                                {selectedAccount.officeNumber}
                            </p>
                            <p>
                                <b>Address:</b> {selectedAccount.address},{' '}
                                {selectedAccount.city}, {selectedAccount.state}{' '}
                                {selectedAccount.zip}
                            </p>
                            <p>
                                <b>EIN:</b> {selectedAccount.ein}
                            </p>
                            <p>
                                <b>Created At:</b>{' '}
                                {new Date(
                                    selectedAccount.createdAt.seconds * 1000
                                ).toLocaleDateString()}
                            </p>
                        </section>

                        <section className='mb-4'>
                            <h3 className='text-lg underline font-bold mb-2'>
                                Documents
                            </h3>
                            <ul>
                                {selectedAccount.driversLicense && (
                                    <li>
                                        <a
                                            href={
                                                selectedAccount.driversLicense
                                                    .url
                                            }
                                            download
                                            target='_blank'
                                            rel='noreferrer'
                                            className='flex items-center font-semibold'
                                        >
                                            <FiDownload className='mr-2' />
                                            Driver's License
                                        </a>
                                    </li>
                                )}
                                {selectedAccount.w9Form && (
                                    <li>
                                        <a
                                            href={selectedAccount.w9Form.url}
                                            download
                                            target='_blank'
                                            rel='noreferrer'
                                            className='flex items-center font-semibold'
                                        >
                                            <FiDownload className='mr-2' />
                                            W9 Form
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </section>
                    </div>
                </Modal>
            </>
        )
    )
}

export default AccountDetailModal
