import React, { useState, useEffect } from 'react'
import { collection, doc, getDocs, updateDoc, getDoc } from 'firebase/firestore'
import { db } from '../config/firebaseConfig'
import Loading from '../components/Loading'
import moment from 'moment'
import { MessageSquare, X } from 'lucide-react'
import AdditionalFilesUpload from '../pages/AdditionalFilesUpload'
import { useLocation } from 'wouter'

const formatTimestamp = (timestamp) => {
    try {
        const date = new Date(timestamp)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    } catch (error) {
        return 'Invalid date'
    }
}

const MessageList = ({ messages, onMoveToRead, onUploadDocs, isUpdating }) => (
    <div className='overflow-y-auto max-h-[400px]'>
        {messages.length > 0 ? (
            messages
                .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                .map((msg, index) => {
                    const isBrokerQuestion =
                        msg.message
                            .toLowerCase()
                            .includes('question for broker') ||
                        msg.message.toLowerCase().includes('broker action')

                    return (
                        <div
                            key={index}
                            className='p-3 border-b border-gray-100'
                        >
                            <div className='flex justify-between items-start mb-1'>
                                <span className='font-medium text-sm text-gray-900'>
                                    {msg.dealName}
                                </span>
                                <span className='text-xs text-gray-500'>
                                    {formatTimestamp(msg.timestamp)}
                                </span>
                            </div>
                            <p className='text-sm text-gray-600 mb-2'>
                                {msg.message}
                            </p>
                            {!msg.read &&
                                (isBrokerQuestion ? (
                                    <button
                                        onClick={() => onUploadDocs(msg)}
                                        disabled={isUpdating}
                                        className='w-full bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-medium py-1.5 px-3 rounded text-sm disabled:opacity-50 disabled:cursor-not-allowed'
                                    >
                                        Upload Docs
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => onMoveToRead([msg.id])}
                                        disabled={isUpdating}
                                        className='w-full bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium py-1.5 px-3 rounded text-sm disabled:opacity-50 disabled:cursor-not-allowed'
                                    >
                                        {isUpdating ? (
                                            <div className='flex items-center justify-center'>
                                                <div className='w-4 h-4 border-2 border-t-2 border-gray-200 rounded-full animate-spin mr-2' />
                                                Moving to Read...
                                            </div>
                                        ) : (
                                            'Move to Read'
                                        )}
                                    </button>
                                ))}
                        </div>
                    )
                })
        ) : (
            <div className='p-4 text-center text-gray-500'>No messages</div>
        )}
    </div>
)

const MessagesModal = ({
    isOpen,
    onClose,
    dealId,
    messages,
    onMoveToRead,
    onUploadDocs,
    isUpdating,
}) => {
    const [activeTab, setActiveTab] = useState('unread')

    if (!isOpen) return null

    // Filter messages for this specific deal
    const dealMessages = messages.filter((msg) => msg.dealId === dealId)
    const unreadMessages = dealMessages.filter((msg) => !msg.read)
    const readMessages = dealMessages.filter((msg) => msg.read)
    const displayMessages =
        activeTab === 'unread' ? unreadMessages : readMessages

    return (
        <div className='fixed inset-0 z-[1001] bg-black bg-opacity-50 flex items-center justify-center'>
            <div className='bg-white rounded-lg w-full max-w-lg mx-4'>
                <div className='flex items-center justify-between p-4 border-b border-gray-200'>
                    <h3 className='font-semibold text-gray-700'>
                        Deal Messages
                    </h3>
                    <X
                        className='h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700'
                        onClick={onClose}
                    />
                </div>

                <div className='flex border-b border-gray-200'>
                    <button
                        className={`flex-1 py-2 px-4 text-sm font-medium ${
                            activeTab === 'unread'
                                ? 'text-themeColorPrimary border-b-2 border-themeColorPrimary'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('unread')}
                    >
                        Unread ({unreadMessages.length})
                    </button>
                    <button
                        className={`flex-1 py-2 px-4 text-sm font-medium ${
                            activeTab === 'read'
                                ? 'text-themeColorPrimary border-b-2 border-themeColorPrimary'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('read')}
                    >
                        Read ({readMessages.length})
                    </button>
                </div>

                <MessageList
                    messages={displayMessages}
                    onMoveToRead={onMoveToRead}
                    onUploadDocs={onUploadDocs}
                    isUpdating={isUpdating}
                />
            </div>
        </div>
    )
}

const MessageCell = ({ deal, messages, onOpenMessages }) => {
    const dealMessages = messages.filter((msg) => msg.dealId === deal.id)
    const unreadCount = dealMessages.filter((msg) => !msg.read).length

    return (
        <div className='relative inline-flex items-center cursor-pointer'>
            <MessageSquare
                className='h-5 w-5 text-gray-500 hover:text-gray-700'
                onClick={() =>
                    dealMessages.length > 0 && onOpenMessages(deal.id)
                }
            />
            {unreadCount > 0 && (
                <span className='absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center'>
                    {unreadCount}
                </span>
            )}
        </div>
    )
}

const PortalPage = ({ user, setSelectedDeal }) => {
    const [deals, setDeals] = useState([])
    const [loading, setLoading] = useState(true)
    const [messages, setMessages] = useState([])
    const [isUpdating, setIsUpdating] = useState(false)
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [selectedMessage, setSelectedMessage] = useState(null)
    const [currentDeal, setCurrentDeal] = useState(null)
    const [selectedDealId, setSelectedDealId] = useState(null)

    // const [, navigate] = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user?.email) {
                    const usersRef = collection(
                        db,
                        process.env.REACT_APP_USERS_DB_REF
                    )
                    const usersSnapshot = await getDocs(usersRef)
                    const userDoc = usersSnapshot.docs.find(
                        (doc) => doc.data().email === user.email
                    )

                    if (userDoc) {
                        const userData = userDoc.data()
                        setMessages(userData.messages || [])
                    }
                }

                const dealsQuery = collection(
                    db,
                    process.env.REACT_APP_DEALS_DB_REF
                )
                const querySnapshot = await getDocs(dealsQuery)
                const dealsData = querySnapshot.docs
                    .map((doc) => ({ id: doc.id, ...doc.data() }))
                    .filter((doc) => doc.userEmail === user?.email)
                    .sort(
                        (a, b) =>
                            b.dateSubmitted.toDate() - a.dateSubmitted.toDate()
                    )

                setDeals(dealsData)
            } catch (error) {
                console.error('Error fetching data:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [user])

    const handleViewDeal = (deal) => {
        window.location = `/deal/modify/${deal.id}`
        setSelectedDeal(deal)
    }

    const handleMoveToRead = async (messageIds) => {
        if (isUpdating) return
        setIsUpdating(true)

        try {
            const usersRef = collection(db, process.env.REACT_APP_USERS_DB_REF)
            const usersSnapshot = await getDocs(usersRef)
            const userDoc = usersSnapshot.docs.find(
                (doc) => doc.data().email === user.email
            )

            if (userDoc) {
                const updatedMessages = messages.map((msg) =>
                    messageIds.includes(msg.id) ? { ...msg, read: true } : msg
                )
                setMessages(updatedMessages)

                const userRef = doc(
                    db,
                    process.env.REACT_APP_USERS_DB_REF,
                    userDoc.id
                )
                await updateDoc(userRef, {
                    messages: updatedMessages,
                })
            }
        } catch (error) {
            console.error('Error updating message status:', error)
        } finally {
            setIsUpdating(false)
        }
    }

    const handleUploadDocs = async (message) => {
        // const url = `/deal/modify/${message.dealId}?messageId=${message.id}`
        // navigate(url)
        setSelectedMessage(message)
        setShowUploadModal(true)
        setSelectedDealId(null)

        if (message.dealId) {
            try {
                const dealRef = doc(
                    db,
                    process.env.REACT_APP_DEALS_DB_REF,
                    message.dealId
                )
                const dealSnap = await getDoc(dealRef)
                if (dealSnap.exists()) {
                    setCurrentDeal(dealSnap.data())
                }
            } catch (error) {
                console.error('Error fetching deal:', error)
            }
        }
    }

    const handleUploadComplete = async () => {
        if (selectedMessage) {
            await handleMoveToRead([selectedMessage.id])
        }
        setShowUploadModal(false)
        setSelectedMessage(null)
        window.location.reload()
    }

    if (loading) {
        return (
            <div className='h-screen'>
                <Loading theme='primary' />
            </div>
        )
    }

    return (
        <div className='bg-themeColorPrimary3 min-h-full flex flex-col w-full px-6 pt-6'>
            <div className='mt-[200px] md:mt-[130px] lg:mt-[130px]'>
                <div className='flex justify-between items-center mb-[30px]'>
                    <h1 className='text-4xl font-bold'>Your Deals</h1>
                </div>

                {/* Deals Table */}
                {deals.length > 0 ? (
                    <table className='w-full px-6'>
                        <thead>
                            <tr className='text-center text-xl'>
                                <th className='pb-4'>Date Submitted</th>
                                <th className='pb-4'>Business</th>
                                <th className='pb-4'>Referrer</th>
                                <th className='pb-4'>Status</th>
                                <th className='pb-4'>Amount Approved</th>
                                <th className='pb-4'>Messages</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deals.map((deal) => (
                                <tr
                                    key={deal.id}
                                    className='mb-4 shadow-lg rounded-2xl border w-full hover:shadow-xl hover:border-themeColorPrimary transition-all'
                                >
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {moment(
                                            (
                                                deal?.dateSubmitted || moment()
                                            ).toDate()
                                        ).format('MM - DD - YYYY')}
                                    </td>
                                    <td
                                        className='text-base text-center mb-2 py-4 underline cursor-pointer font-bold'
                                        onClick={() => handleViewDeal(deal)}
                                    >
                                        {deal?.businessName}
                                    </td>
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {deal?.referrer}
                                    </td>
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {deal?.status}
                                    </td>
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {deal?.amountApproved
                                            ? `$${deal.amountApproved.toLocaleString()}`
                                            : '-'}
                                    </td>
                                    <td className='text-center py-4'>
                                        <MessageCell
                                            deal={deal}
                                            messages={messages}
                                            onOpenMessages={setSelectedDealId}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>
                        <table className='w-full px-6'>
                            <thead>
                                <tr className='text-center text-xl'>
                                    <th className='pb-4'>Date Submitted</th>
                                    <th className='pb-4'>Business</th>
                                    <th className='pb-4'>Referrer</th>
                                    <th className='pb-4'>Status</th>
                                    <th className='pb-4'>Amount Approved</th>
                                    <th className='pb-4'>Messages</th>
                                </tr>
                            </thead>
                        </table>
                        <p className='w-full text-center mt-4 text-2xl font-bold text-red-600'>
                            No deals found!
                        </p>
                    </div>
                )}
            </div>

            {/* Messages Modal */}
            <MessagesModal
                isOpen={selectedDealId !== null}
                onClose={() => setSelectedDealId(null)}
                dealId={selectedDealId}
                messages={messages}
                onMoveToRead={handleMoveToRead}
                onUploadDocs={handleUploadDocs}
                isUpdating={isUpdating}
            />

            {/* Upload Modal */}
            {showUploadModal && (
                <div className='fixed inset-0 z-[1001] bg-black bg-opacity-50 flex items-center justify-center'>
                    <div className='bg-white rounded-lg w-full max-w-4xl mx-4 max-h-[90vh] overflow-y-auto'>
                        <div className='p-4 border-b border-gray-200 flex justify-between items-center'>
                            <h2 className='text-xl font-bold'>
                                Upload Additional Documents
                            </h2>
                            <button
                                onClick={() => setShowUploadModal(false)}
                                className='text-gray-500 hover:text-gray-700'
                            >
                                <X className='h-6 w-6' />
                            </button>
                        </div>
                        <div className='p-4'>
                            <AdditionalFilesUpload
                                dealId={selectedMessage?.dealId}
                                userId={user?.uid}
                                userEmail={user?.email}
                                onUploadComplete={handleUploadComplete}
                                currentDeal={currentDeal}
                                selectedMessage={selectedMessage}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PortalPage
